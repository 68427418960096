<template>
  <div v-if="contents && sliderOptions" class="slider-container">
    <swiper
      v-if="contents && sliderOptions"
      :options="sliderOptions"
      :ref="swiperId"
      @slideChangeTransitionEnd="startAutoPlayVideo()"
      @ready="startAutoPlayVideo(), pushDLEvent()"
      @slideChange="onSlideChange()"
    >
      <swiper-slide v-for="banner in contents" :key="banner.bannerId">
        <div v-if="banner.isDynamicBanner">
          <div :style="getStyleDynamicDescription(banner)" class="banner-dynamic-content">
            {{ banner.description }}
          </div>
          <img
            v-if="banner.subImage"
            :style="getStyleDynamicSubImage(banner)"
            class="banner-dynamic-content"
            :src="banner.subImage"
            alt="pm-aktif-logo"
          />
        </div>
        <BrandVimeoPlayer
          v-if="banner.embedVideo"
          :data="banner"
          :id="banner.bannerId"
          :logger="logger"
          :hasCover="true"
          :isChangedSlider="isChangedSlider"
          @play="onVideoPlay(banner)"
          @pause="onVideoPause()"
        />
        <div id="iframeContainer" v-else-if="banner.iFrame">
          <iframe
            title="player"
            id="frame"
            class="player-iframe"
            :src="banner.iFrame"
            @load="load(banner)"
          ></iframe>
        </div>
        <BrandMediaContentBox
          v-else
          :mediaSrc="banner.imageURL"
          :aspectRatios="media.aspect.by_16_9"
          :href="banner.link"
          :isSliderClick="true"
          @onClickSliderContent="onClickSliderItem(banner)"
        />
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>
  </div>
</template>
<script>
import { Common } from '@/services/Api/index';
import BrandMediaContentBox from '@/components/brand/BrandMediaContentBox/BrandMediaContentBox.vue';
import MEDIA_CONSTANTS from '@/constants/media.constants.js';
import BrandVimeoPlayer from '@/components/brand/BrandVimeoPlayer/BrandVimeoPlayer.vue';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
import gtmUtils from '@/mixins/gtmUtils.js';
export default {
  name: 'BrandContentSlider',
  components: {
    BrandVimeoPlayer,
    BrandMediaContentBox,
    swiper,
    swiperSlide,
  },
  props: {
    slides: {
      type: Array,
    },
    swiperId: {
      type: String,
      default: 'mySwiper',
    },
    config: {
      type: Object,
    },
    logger: {
      type: String,
      default: '',
    },
  },
  mixins: [gtmUtils],
  methods: {
    getStyleDynamicDescription(dynamicBanner) {
      return `top:${dynamicBanner.topDescription}px; left:${
        dynamicBanner.leftDescription
      }px; font-family:${dynamicBanner.fontFamily}; font-size:${
        dynamicBanner.fontSize
      }px; font-style:${dynamicBanner.isBold ? 'bold' : 'none'}; color:${
        dynamicBanner.descriptionColor
      }; width:${dynamicBanner.widthDescription}px; height:${dynamicBanner.heightDescription}px; `;
    },
    getStyleDynamicSubImage(dynamicBanner) {
      return `top:${dynamicBanner.topSubImage}px; left:${dynamicBanner.leftSubImage}px; width: ${dynamicBanner.widthSubImage}px; height: ${dynamicBanner.heightSubImage}px;`;
    },
    onIframeClick() {
      this.isClicked = true;
      if (this.isClicked) this.$refs[this.swiperId].swiper.navigation.prevEl.style.height = '500px';
      this.$refs[this.swiperId].swiper.navigation.nextEl.style.height = '500px';
    },
    load(banner) {
      this.currentBanner = banner;
      let videoPlay = () => {
        this.onIFramePlay();
      };
      window.addEventListener('blur', function() {
        videoPlay();
      });
    },

    onSlideChange() {
      this.isChangedSlider = !this.isChangedSlider;
      if (this.scrolled > 610) return;
      this.pushDLEvent();
    },
    onClickSliderItem(banner) {
      this.pushDataLayerEvent('clickContent', {
        ...banner,
      });
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('select_promotion', {
        creative: 'Banner',
        bannerId: banner.bannerId,
        title: banner.title,
        link: banner.link,
      });
    },
    onChangedSliderItem(banner) {
      this.pushDataLayerEvent('changedContent', [
        {
          id: banner?.bannerId ?? '',
          name: banner?.title ?? '',
          creative: banner?.imageURL ?? '',
          position: banner?.position ?? '',
        },
      ]);
      if (!this.viewed_promotions.includes(banner.bannerId)) {
        this.pushDataLayerEvent('ecommerce');
        this.pushDataLayerEvent('view_promotion', {
          creative: 'Banner',
          bannerId: banner.bannerId,
          title: banner.title,
          link: banner.link,
        });
        this.viewed_promotions.push(banner.bannerId);
      }
    },
    pushDLEvent() {
      if (this.contents.length) {
        let sw = this.$refs[this.swiperId];
        let index = sw.swiper.activeIndex;
        this.onChangedSliderItem(this.contents[index]);
      }
    },
    onVideoPause() {
      this.$refs[this.swiperId].swiper.pagination.el.style.display = '';
    },
    onVideoPlay(banner) {
      if (!this.$refs[this.swiperId]?.swiper) return;
      else {
        this.$refs[this.swiperId].swiper.pagination.el.style.display = 'none';
        this.$refs[this.swiperId].swiper.autoplay.stop();
        this.onClickSliderItem(banner);
      }
    },
    onIFramePlay() {
      if (!this.$refs[this.swiperId]?.swiper) return;
      else {
        this.$refs[this.swiperId].swiper.pagination.el.style.display = 'none';
        this.$refs[this.swiperId].swiper.autoplay.stop();
      }
    },
    startAutoPlayVideo() {
      if (this.autoPlay) {
        let sw = this.$refs[this.swiperId];
        let activeIndex = sw.swiper.activeIndex;
        let slides = sw.swiper.slides;
        if (
          slides &&
          slides.length > 0 &&
          this.contents &&
          this.contents.length > 0 &&
          this.contents[activeIndex].embedVideo
        ) {
          let vidCover = slides[activeIndex].querySelector('.iframe-cover');
          vidCover.click();
        }
      }
    },
    setSwiperOptions(options) {
      if (options) {
        if (options.bannerSliderType) {
          if (options.bannerSliderType.type === 3 && options.period > 0) {
            this.sliderOptions.autoplay.delay = options.period * 2000;
            this.autoPlay = false;
          } else {
            delete this.sliderOptions.autoplay;
            this.autoPlay = options.autoplay;
          }

          if (options.bannerSliderType.type === 1) {
            this.contents = [this.contents[0]];
            this.sliderOptions.resistance = true;
            this.sliderOptions.resistanceRatio = 0;
          }
        }
      }
    },
    initContentSlider() {
      if (this.contents?.length > 0) {
        setTimeout(() => {
          if (!this.viewed_promotions.includes(this.contents[0]?.bannerId)) {
            this.pushDataLayerEvent('ecommerce');
            this.pushDataLayerEvent('view_promotion', {
              creative: 'Banner',
              bannerId: this.contents[0]?.bannerId,
              title: this.contents[0]?.title,
              link: this.contents[0]?.link,
            });
            this.viewed_promotions.push(this.contents[0]?.bannerId);
          }
        }, 1000);
      }
    },
  },
  created() {
    if (!this.slides || !this.config) {
      let serviceCall = null;
      if (this.swiperId === 'mainSliderFun') {
        serviceCall = Common.getFunBanners();
      } else {
        serviceCall = Common.getMainBanners();
      }

      let position = 0;
      serviceCall.then(res => {
        if (res.data && res.data.Data) {
          const dynamicBanners = res.data.Data.dynamicBanners.map(banner => ({
            ...banner,
            image: banner.bannerImage,
            isDynamicBanner: true,
            bannerId: banner.id,
          }));
          const combinedBanners = [...res.data.Data.banners, ...dynamicBanners];

          combinedBanners.sort((a, b) => a.order - b.order);

          this.contents = combinedBanners.map(({ image: imageURL, ...rest }) => ({
            imageURL,
            position: ++position,
            ...rest,
          }));
          this.initContentSlider();
          this.options = res.data.Data.config;
          this.setSwiperOptions(this.options);
        }
      });
    } else {
      this.contents = this.slides.map(
        ({ contentId: bannerId, largeImageURL: imageURL, ...rest }) => ({
          bannerId,
          imageURL,
          ...rest,
        }),
      );
      this.options = this.config;
      this.setSwiperOptions(this.options);
    }

    window.addEventListener('scroll', () => {
      this.scrolled = window.scrollY;
      if (!this.$refs[this.swiperId]?.swiper) return;
      else if (this.scrolled < 610) {
        this.$refs[this.swiperId].swiper.autoplay.stop();
      }
    });
  },
  data() {
    return {
      contents: null,
      options: null,
      autoPlay: true,
      dataLayer: [],
      viewed_promotions: [],
      scrolled: 0,
      promotionsForClick: [],
      promotionsForChanged: [],
      currentBanner: null,
      sliderOptions: {
        slidesPerView: 1,
        centeredSlides: true,
        resistance: true,
        resistanceRatio: 0.7,
        watchOverflow: true,
        autoplay: { delay: 5000, disableOnInteraction: true },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      isClicked: false,
      isChangedSlider: false,
    };
  },
  computed: {
    media() {
      return {
        aspect: MEDIA_CONSTANTS.MEDIA_ASPECT_RATIOS,
        type: MEDIA_CONSTANTS.MEDIA_CONTENT_TYPES,
      };
    },
  },
  mounted() {
    this.isClicked = false;
    let onClick = () => {
      this.onIframeClick();
      this.pushDataLayerEvent('clickContent', {
        ...this.currentBanner,
      });
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('select_promotion', {
        creative: 'Banner',
        bannerId: this.currentBanner.bannerId,
        title: this.currentBanner.title,
        link: this.currentBanner.link,
      });
    };
    var eventListener = window.addEventListener('blur', function() {
      if (!this.isClicked && document.activeElement === document.getElementById('frame')) {
        this.isClicked = true;
        onClick();
        setTimeout(function() {
          window.focus();
        }, 0);
      }
      window.removeEventListener('blur', eventListener);
    });
  },
};
</script>
<style lang="scss" scoped>
.swiper-pagination {
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
}
.slider-container {
  .bg-image {
    background-size: cover;
    background-position: center center;
  }
}

.slider-container {
  position: relative;
  .image-wrapper {
    position: absolute;
  }

  /deep/ .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 29px;
  }
  /deep/ .swiper-pagination-bullet {
    margin: 0 4px;
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 0.5;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    &-active {
      width: 14px;
      height: 14px;
      opacity: 1;
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 80px;
    height: 60%;
    background-size: cover;
    opacity: 0;
    z-index: 2;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
  .swiper-button-next {
    right: 0;
    background-image: url('~@/assets/bg/arrow-bg-right.png');
  }
  .swiper-button-prev {
    left: 0;
    background-image: url('~@/assets/bg/arrow-bg-left.png');
  }
  &:hover {
    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
      opacity: 0.3;
      pointer-events: auto;
    }
    .swiper-button-prev,
    .swiper-button-next {
      opacity: 1;
    }
  }
}
.player-iframe {
  max-width: 1024px;
  height: 573px;
  width: 1025px;
}
.banner-dynamic-content {
  position: absolute;
  z-index: 10;
}
</style>
