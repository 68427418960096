var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"divFeedWrapper"}},[_c('div',{style:([
      _vm.isPartner
        ? {
            borderTop: '1px solid red',
            background: ("url('" + (require('@/assets/bg/bg-image.png')) + "')"),
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top center',
            backgroundSize: '100% 650px',
          }
        : '' ])},[_c('div',{staticClass:"content-layout-fixer"},[_c('div',{staticClass:"insider",attrs:{"id":"inStory"}}),_vm._m(0),_c('BrandContentSlider',{attrs:{"swiperId":"mainSliderFeed"}}),_c('MultiBannerSlider',{on:{"onClickSliderContent":_vm.onClickSliderContent,"onSubSliderChanged":_vm.onSubSliderChanged,"initSubSlider":_vm.initSubSlider}}),_c('br'),_c('br'),_c('hr'),(_vm.categories && _vm.categories.length)?_c('div',_vm._l((_vm.categories),function(category,index){return _c('div',{key:index},[(category.feeds)?_c('BrandContentList',{attrs:{"contents":category.feeds,"title":category.name,"id":("observer_" + (category.id) + "_" + index),"showButton":category.continue,"eventTitle":("Anasayfa / " + (category.name))},on:{"showMoreClicked":function($event){return _vm.showMore(category, index)}}}):_vm._e()],1)}),0):_vm._e()],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ad-wrapper-large"},[_c('div',{attrs:{"id":"div-gpt-ad-1587046762079-0"}})])}]

export { render, staticRenderFns }