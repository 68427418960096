<template>
  <div id="divFeedWrapper">
    <div
      :style="[
        isPartner
          ? {
              borderTop: '1px solid red',
              background: `url('${require('@/assets/bg/bg-image.png')}')`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'top center',
              backgroundSize: '100% 650px',
            }
          : '',
      ]"
    >
      <div class="content-layout-fixer">
        <div class="insider" id="inStory"></div>
        <div class="ad-wrapper-large">
          <div id="div-gpt-ad-1587046762079-0"></div>
        </div>
        <BrandContentSlider swiperId="mainSliderFeed" />
        <MultiBannerSlider
          @onClickSliderContent="onClickSliderContent"
          @onSubSliderChanged="onSubSliderChanged"
          @initSubSlider="initSubSlider"
        />
        <br /><br />
        <hr />
        <div v-if="categories && categories.length">
          <div v-for="(category, index) in categories" :key="index">
            <BrandContentList
              v-if="category.feeds"
              :contents="category.feeds"
              :title="category.name"
              :id="`observer_${category.id}_${index}`"
              :showButton="category.continue"
              @showMoreClicked="showMore(category, index)"
              :eventTitle="`Anasayfa / ${category.name}`"
            ></BrandContentList>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BrandContentList from '@/components/brand/BrandContentList/BrandContentList';
import BrandContentSlider from '@/components/brand/Slider/BrandContentSlider.vue';
import MultiBannerSlider from '@/components/brand/Slider/MultiBannerSlider.vue';
import { Common, Content } from '@/services/Api/index';
import gtmUtils from '@/mixins/gtmUtils.js';
import StorageProps from '@/mixins/storageProps.js';
import { mapGetters } from 'vuex';

export default {
  name: 'Feed',
  mixins: [StorageProps, gtmUtils],
  components: {
    BrandContentList,
    BrandContentSlider,
    MultiBannerSlider,
  },
  data() {
    return {
      contentTypes: [],
      categories: [],
      mainSlider: [],
      viewed_promotions: [],
    };
  },
  computed: {
    ...mapGetters('app', ['getLastPosition']),
    ...mapGetters('auth', ['getUserDetails']),
  },
  methods: {
    getFeedFilters() {
      Content.getFeedFilters().then(res => {
        if (
          res.data &&
          res.data.Data &&
          res.data.Data.contentTypes &&
          res.data.Data.contentTypes.length > 0
        ) {
          const {
            Data: { contentTypes },
          } = res.data;
          this.$store.dispatch('app/setContentTypes', contentTypes);
          this.contentTypes = contentTypes;
          this.contentTypes.forEach((item, index) => {
            Common.getFeeds(0, 3, item.contentTypeId).then(res => {
              if (res.data && res.data.Data) {
                const {
                  Data: { feeds, isFeedContinue },
                } = res.data;
                let newItem = {
                  continue: isFeedContinue,
                  feeds: feeds,
                  name: item.contentName,
                  id: item.contentTypeId,
                  startIndex: 3,
                  index: index,
                };
                this.categories.push(newItem);

                this.categories = this.categories.sort(function(a, b) {
                  return a.index - b.index;
                });
                this.$store.dispatch('app/setCategories', this.categories);
              }
            });
          });
          setTimeout(() => {
            window.scrollTo(0, this.getLastPosition);
          }, 500);
        }
      });
    },
    onClickSliderContent(banner) {
      this.pushDataLayerEvent('clickContent', banner);
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('select_promotion', {
        creative: 'Banner',
        bannerId: banner.bannerId,
        title: banner.title,
        link: banner.link,
      });
    },
    initSubSlider(banners) {
      let mappedEventData = [];
      banners.forEach(m => {
        if (!this.viewed_promotions.includes(m.bannerId)) {
          this.pushDataLayerEvent('ecommerce');
          this.pushDataLayerEvent('view_promotion', {
            creative: 'Subbanner',
            bannerId: m.bannerId,
            title: m.title,
            link: m.link,
          });
          this.viewed_promotions.push(m.bannerId);
        }
        mappedEventData.push({
          id: m.bannerId,
          name: m.title,
          creative: m.imageURL,
          position: m.position,
        });
      });

      this.pushDataLayerEvent('changedContent', mappedEventData);
    },
    onSubSliderChanged(banner) {
      console.log(banner);
      this.pushDataLayerEvent('changedContent', {
        id: banner.bannerId,
        name: banner.title,
        creative: banner.imageURL,
        position: banner.position,
      });
      if (!this.viewed_promotions.includes(banner.bannerId)) {
        this.pushDataLayerEvent('ecommerce');
        this.pushDataLayerEvent('view_promotion', {
          creative: 'Subbanner',
          bannerId: banner.bannerId,
          title: banner.title,
          link: banner.link,
        });
        this.viewed_promotions.push(banner.bannerId);
      }
    },
    getMoreFeeds(startIndex, category, index) {
      Common.getFeeds(startIndex, 9, category.id).then(res => {
        const {
          Data: { feeds, isFeedContinue },
        } = res.data;
        this.categories[index].continue = isFeedContinue;
        this.categories[index].feeds.push(...feeds);
        this.categories[index].startIndex = this.categories[index].feeds.length;
        this.$store.dispatch('app/setCategories', this.categories);
      });
    },
    showMore(category, index) {
      this.getMoreFeeds(category.startIndex, category, index);
    },
    onIframeClick() {
      this.pushDataLayerEvent('videoPlayerStart', {
        videoPlayerStart: true,
        customerCode: this.getUserDetails?.customer_code,
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('app/setLastPosition', window.scrollY);
    next();
  },
  mounted() {
    this.getFeedFilters();
    let isClicked = false;
    let onClick = () => {
      this.onIframeClick();
    };
    var eventListener = window.addEventListener('blur', function() {
      if (!isClicked && document.activeElement === document.getElementById('iframe')) {
        isClicked = true;
        onClick();
        setTimeout(function() {
          window.focus();
        }, 0);
      }
      window.removeEventListener('blur', eventListener);
    });
    //initialize google ads for homepage
    this.pushAds('large');

    var storyDiv = document.querySelector('#inStory');
    if (storyDiv) {
      document.body.addEventListener('click', event => {
        if (storyDiv.contains(event.target)) {
          this.pushDataLayerEvent('story');
        }
      });
    }
  },
};
</script>

<style scoped lang="scss">
/deep/ .content-card-list {
  margin: 0;
}
.player-iframe {
  max-width: 1025px;
  height: 573px;
  width: 1025px;
  margin-bottom: 15px;
}
</style>
