<template>
  <div class="feed-item-container">
    <BrandMediaContentBox
      class="feed-media"
      :aspectRatios="propData.media.aspect.by_16_9"
      :as="propData.media.type.image"
      :mediaSrc="modelData.imageURL !== null ? modelData.imageURL : ''"
      @click.native="detailClick(modelData.feedType, modelData.surveyType)"
    >
      <div class="feed-info" id="feed_info">
        <VueBadge v-if="modelData.feedType === 1" :radius="20" class="overlay badge overlay-left">
          <LikeButton
            class="btn-feed-like"
            :status="modelData.liked"
            :count.sync="modelData.likeCount"
            :contentId="modelData.feedId"
            :contentName="modelData.title"
            @click.native="sendLikeEvent"
          />
        </VueBadge>
        <VueBadge v-if="modelData.feedType === 1" :radius="20" class="feed-category">{{
          modelData.category.categoryText
        }}</VueBadge>
      </div>
    </BrandMediaContentBox>
    <div class="feed-item-summary">
      <VueText
        v-if="modelData.likeCount && modelData.likeCount > 0"
        sizeLevel="3"
        class="like-count"
      >
        {{ $t('feedPage.body.likeCount', { count: modelData.likeCount }) }}
      </VueText>
      <div v-else class="like-space"></div>
      <VueText
        @click.native="detailClick(modelData.feedType, modelData.surveyType)"
        sizeLevel="6"
        weightLevel="3"
        class="feed-item-title"
        >{{ modelData.title }}</VueText
      >
      <VueText
        @click.native="detailClick(modelData.feedType, modelData.surveyType)"
        v-if="modelData.shortDescription"
        sizeLevel="4"
        class="feed-item-content"
        >{{
          modelData.shortDescription.length > 120
            ? modelData.shortDescription.slice(0, 120) + '...'
            : modelData.shortDescription
        }}</VueText
      >
    </div>
  </div>
</template>
<script>
import MEDIA_CONSTANTS from '@/constants/media.constants.js';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueBadge from '@/components/shared/VueBadge/VueBadge.vue';
import BrandMediaContentBox from '@/components/brand/BrandMediaContentBox/BrandMediaContentBox.vue';
import LikeButton from '@/components/brand/BrandButton/LikeButton.vue';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesSurvey from '@/router/routes/secure/RoutesSurvey';
import RoutesPlayAndWin from '@/router/routes/secure/RoutesPlayAndWin';
import gtmUtils from '@/mixins/gtmUtils.js';

export default {
  name: 'FeedItem',
  props: {
    model: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
    },
    eventTitle: {
      type: String,
      default: '',
    },
    listedUnder: {
      type: String,
      default: '',
    },
  },
  components: {
    VueText,
    VueBadge,
    BrandMediaContentBox,
    LikeButton,
  },
  mixins: [gtmUtils],
  data() {
    return {
      modelData: Object.assign({}, this.model),
      link: null,
    };
  },
  computed: {
    propData() {
      const media = {
        aspect: MEDIA_CONSTANTS.MEDIA_ASPECT_RATIOS,
        type: MEDIA_CONSTANTS.MEDIA_CONTENT_TYPES,
      };
      return { media };
    },
  },
  mounted() {
    if (this.modelData.feedType === 1) {
      this.link = {
        name: `${RoutesSecure.FeedDetail.name}`,
        params: { id: this.modelData.feedId },
      };
    } else if (this.modelData.feedType === 2) {
      if (this.modelData.surveyType === 2 || this.modelData.surveyType === 1) {
        this.link = {
          name: `${RoutesSurvey.TakeSurvey.name}`,
          params: { id: this.modelData.feedId },
        };
      } else if (this.modelData.surveyType === 3) {
        this.link = {
          name: `${RoutesPlayAndWin.PlayAndWinDetail.name}`,
        };
      }
    }
    setTimeout(() => {
      this.$nextTick(() => {
        this.initObserver();
      });
    }, 1000);
  },
  methods: {
    initObserver() {
      const observer = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting) {
            this.pushDataLayerEvent('ecommerce');
            this.pushDataLayerEvent('view_promotion', {
              link: this.$router.resolve(this.link)?.href,
              bannerId: this.modelData.feedId,
              title: this.modelData.title,
              creative:
                this.modelData.feedType === 1
                  ? 'Content'
                  : this.modelData.surveyType === 2 || this.modelData.surveyType === 1
                  ? 'Survey'
                  : this.modelData.surveyType === 3
                  ? RoutesPlayAndWin.PlayAndWinDetail.name
                  : '',
              type: this.listedUnder,
              group: this.modelData.category?.categoryText,
            });
            observer.unobserve(entries[0].target);
          }
        },
        { threshold: 0.5 },
      );
      observer.observe(this.$el);
    },
    detailClick(feedType) {
      if (this.link) {
        if (feedType === 1) this.$emit('contentClick');
        this.$router.push(this.link);
      } else {
        const errModalConfig = {
          isModalOpened: true,
          modalText: 'Post bulunamadı!',
          modalType: 'alert',
          alertType: 'error',
          firstButtonText: 'Tamam',
        };
        this.$store.dispatch('app/setModalConfig', errModalConfig);
        return;
      }
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('select_promotion', {
        link: this.$router.resolve(this.link)?.href,
        bannerId: this.modelData.feedId,
        title: this.modelData.title,
        creative:
          this.modelData.feedType === 1
            ? 'Content'
            : this.modelData.surveyType === 2 || this.modelData.surveyType === 1
            ? 'Survey'
            : this.modelData.surveyType === 3
            ? RoutesPlayAndWin.PlayAndWinDetail.name
            : '',
        type: this.listedUnder,
        group: this.modelData.category?.categoryText,
      });
    },
    sendLikeEvent() {
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('favorilere_ekle', {
        type: this.modelData.category.categoryText,
      });
    },
  },
};
</script>
<style scoped lang="scss">
$overlay-side-space: palette-space-level(20);

.feed-media {
  position: relative;
  display: flex;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  overflow: hidden;
  height: 100%;

  &:before {
    content: '';
    display: block;
  }

  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.feed-info {
  padding: 10px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .overlay {
    font-size: palette-font-size-level(5);
    font-weight: palette-font-weight-level(3);
    -webkit-box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
  }
}

.feed-item-container {
  margin-bottom: 45px;
  .feed-category {
    font-size: 12px;
    padding: 10px;
    -webkit-box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
    font-weight: 500;
    max-width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .feed-item-summary {
    padding: 20px 20px 0;

    .feed-item-title {
      padding-bottom: 5px;
      cursor: pointer;
    }
    .like-count {
      padding-bottom: 5px;
    }
    .feed-item-content {
      color: $brand-link-primary-hover-color;
      cursor: pointer;
    }
  }
}

.like-space {
  height: 19px;
}
</style>
